import React from 'react'
import o2 from "../assets/o2.jpeg";
import upwork from "../assets/upwork.png"
import Button from '@mui/material/Button';
function Developer() {
  return (
    <div className="container">
        {/* <h1>Olayinka Fakanbi</h1> */}
        <form className="form">
      
      <img loading="lazy" src={o2} className="pfp1 animate__backInDown" alt="logo" />
      
        <h2 className="paragraph">
            
            {/* I have a passion for Robotics and creating user-friendly and responsive web applications that provide value to users. 
            I am constantly learning new technologies and improving my skills to stay up-to-date with the latest trends in web development. */}
            I’m Olayinka, the visionary behind OlaFolio, a platform that bridges technology and human-centric design through innovation, creativity, and cutting-edge solutions.

With expertise in Full Stack Development, AI Prompt Engineering, and Agentic System Design, I specialize in creating intelligent systems and transformative web experiences that push boundaries and deliver tangible value.

What I Bring to the Table:

Proven skills in technologies like Python, JavaScript, React, TypeScript, and AI-powered systems.
Proficiency in 3D, AR/VR, and Data Visualization, making complex concepts engaging and interactive.
Passion for building agentic AI solutions, including "Agent Ola," an intelligent conversational assistant for my portfolio, tailored to guide and connect users effectively.

        </h2>
        {/* <p><img src={upwork} width="130px" height="50px" /></p> */}
        {/* <h2>
        Upwork Freelance Profile Link Below:
        
        </h2> */}
        {/* <Button target="_blank" variant="contained" color="primary" href="https://www.upwork.com/freelancers/~0191a94ab83fc1347e?mp_source=share">
            Learn More </Button> */}
    
        
      </form>
    </div>
  )
}

export default Developer